import './App.css';
import Bton from './Componentes/Botones.js';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo_diversey from './Assets/img/logo_diversey.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightLong } from "@fortawesome/free-solid-svg-icons"


function App() {
  //l
  return (
    <div className="App-Window">
       
        <br></br>

        <div className='App-Logo'>
          <img src={logo_diversey}/>
        </div>

        <div className="App-Butons">

          <div className="cuadrado2">
            <div className="box-one">
              Área Técnica Chile
            </div>
            <div className="box-two">
              <div className="boton"><a href="http://diversey.gpsdroid.cl"><FontAwesomeIcon icon={ faRightLong } size='2xl'/></a></div>
            </div>
          </div>

          <div className="cuadrado2">
            <div className="box-one">
              Área Técnica Chile V2
            </div>
            <div className="box-two">
              <div className="boton"><a href="http://chile2.diverseydroid.cl"><FontAwesomeIcon icon={ faRightLong } size='2xl'/></a></div>
            </div>
          </div>

          <div className="cuadrado2">
            <div className="box-one">
              Consulting
            </div>
            <div className="box-two">
              <div className="boton"><a href="http://consulting.diverseydroid.cl"><FontAwesomeIcon icon={ faRightLong } size='2xl'/></a></div>
            </div>
          </div>

          <div className="cuadrado2">
            <div className="box-one">
              Área Técnica Peru
            </div>
            <div className="box-two">
              <div className="boton"><a href="http://peru.diverseydroid.cl"><FontAwesomeIcon icon={ faRightLong } size='2xl'/></a></div>
            </div>
          </div>

          <div className="cuadrado2">
            <div className="box-one">
              Pantallas
            </div>
            <div className="box-two">
              <div className="boton"><a href="http://pantallas.diverseydroid.cl"><FontAwesomeIcon icon={ faRightLong } size='2xl'/></a></div>
            </div>
          </div>

          {/*<div className="cuadrado2"> agregar bton
            <div className="box-one">
              KData
            </div>
            <div className="box-two">
              <div className="boton"><a href="#"><FontAwesomeIcon icon={ faRightLong } size='2xl'/></a></div>
            </div>
          </div>*/}
          
          <br></br>

        </div>
       
   
                        
       
      
    </div>
  );
}

export default App;
